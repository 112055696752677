<template>
  <div>
    <el-card class="box-card">
      <!--顶部面包靴-->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/volunteer/volunteerManage' }" class="fs20">志愿者管理</el-breadcrumb-item>
        <el-breadcrumb-item class="fs20">积分记录({{$route.query.volunteerName}})</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="search-table-box mt30">
        <div>
          <el-form ref="form" :inline="true" :model="queryParams" label-width="80px">
            <el-form-item label="积分体系">
              <el-select v-model="queryParams.integralSystemId" placeholder="请选择" clearable>
                <el-option :label="item.systemName" :value="item.id" v-for="item in integralSystemList" :key="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchList">查询</el-button>
            </el-form-item>
            <div class="down-export">
              <el-button type="primary" @click="add()">加分</el-button>
            </div>
          </el-form>
        </div>
        <el-table v-loading="loading" style="width: 100%; margin-top: 10px" :data="tableData" border
          :header-cell-style="headerCellStyle">
          <template slot="empty">
            <IsEmpty />
          </template>
          <el-table-column type="index" label="序号" width="50" align="center" />
          <el-table-column prop="integralSystemName" label="积分体系" align="center" />
          <el-table-column prop="totalIntegral" label="当前积分" align="center" />
          <el-table-column label="操作" width="250" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="reduce(scope.row)">减分</el-button>
              <el-button type="text" @click="exchange(scope.row)">兑换</el-button>
              <el-button type="text" @click="transfer(scope.row)">转让</el-button>
              <el-button type="text" @click="toDetail(scope.row)">积分明细</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryParams.pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="queryParams.pageSize"
          layout="total, sizes, prev, pager, next" :total="total">
        </el-pagination>
      </div>
    </el-card>
    <!-- 增加积分 -->
    <AddIntegral v-if="addIntegralVisible" :addIntegralVisible.sync="addIntegralVisible"
      :volunteerId="queryParams.volunteerId" @updateData="getList" />
    <!-- 减少积分 -->
    <ReduceIntegral v-if="reduceIntegralVisible" :reduceIntegralVisible.sync="reduceIntegralVisible"
      :volunteerId="queryParams.volunteerId" :systemId="currentSystemId" :integral="totalIntegral"
      @updateData="getList" />
    <!-- 兑换积分 -->
    <ExchangeIntegral v-if="exchangeIntegralVisible" :exchangeIntegralVisible.sync="exchangeIntegralVisible"
      :volunteerId="queryParams.volunteerId" :systemId="currentSystemId" :integral="totalIntegral"
      @updateData="getList" />
    <!-- 转让积分 -->
    <TransferIntegral v-if="transferIntegralVisible" :transferIntegralVisible.sync="transferIntegralVisible"
      :info="info" @updateData="getList" />
  </div>
</template>

<script>
import {
  getIntegralRecordList,
  getIntegralSummaryList,
  updateIntegralSystem
} from '@/api/integralSystem'
import AddIntegral from './components/AddIntegral'
import ReduceIntegral from './components/ReduceIntegral'
import TransferIntegral from './components/TransferIntegral'
import ExchangeIntegral from './components/ExchangeIntegral'
export default {
  components: {
    AddIntegral,
    ReduceIntegral,
    ExchangeIntegral,
    TransferIntegral
  },
  data() {
    return {
      addIntegralVisible: false,
      reduceIntegralVisible: false,
      exchangeIntegralVisible: false,
      transferIntegralVisible: false,
      currentSystemId: '',
      totalIntegral: 0,
      loading: false,
      tableData: [],
      queryParams: {
        //查询参数
        pageNum: 1,
        pageSize: 10,
        systemName: '',
        volunteerId: '',
        integralSystemId: ''
      },
      total: 0,
      info: {},
      integralSystemList: []
    }
  },
  mounted() {
    this.queryParams.volunteerId = Number(this.$route.query.volunteerId)
    this.getList()
    this.getIntegralRecordList()
  },
  methods: {
    async getIntegralRecordList() {
      const res = await getIntegralRecordList()
      this.integralSystemList = res.data
    },
    async getList() {
      this.loading = true
      const res = await getIntegralSummaryList(this.queryParams)
      this.loading = false
      this.tableData = res.data.rows
      this.total = res.data.total
    },
    add() {
      this.addIntegralVisible = true
      // this.$router.push({
      //   path: '/volunteer/integralSystem/add',
      //   query: { type: 1 }
      // })
    },
    reduce(row) {
      // if (!row.totalIntegral) {
      //   this.$message.error('当前志愿者积分为0时，不允许减少积分')
      //   return false
      // }
      this.totalIntegral = row.totalIntegral
      this.currentSystemId = row.integralSystemId
      this.reduceIntegralVisible = true
    },
    exchange(row) {
      // if (!row.totalIntegral) {
      //   this.$message.error('当前志愿者积分为0时，不允许兑换积分')
      //   return false
      // }
      this.totalIntegral = row.totalIntegral
      this.currentSystemId = row.integralSystemId
      this.exchangeIntegralVisible = true
    },
    transfer(row) {
      // if (!row.totalIntegral) {
      //   this.$message.error('当前志愿者积分为0时，不允许转让积分')
      //   return false
      // }
      this.info = {
        volunteerId: this.queryParams.volunteerId,
        systemId: row.integralSystemId,
        integral: row.totalIntegral,
        idNumber: this.$route.query.idNumber,
        name: this.$route.query.volunteerName
      }
      this.transferIntegralVisible = true
    },
    edit(row) {
      this.$router.push({
        path: '/volunteer/integralSystem/add',
        query: { info: JSON.stringify(row), type: 2 }
      })
    },
    toDetail(row) {
      this.$router.push({
        path: '/volunteer/integralManage/integralDetails',
        query: {
          volunteerId: this.queryParams.volunteerId,
          volunteerName: this.$route.query.volunteerName
        }
      })
    },
    async changeStatus(row) {
      const state = row.state === '1' ? '0' : '1'
      const res = await updateIntegralSystem({ id: row.id, state: state })
      row.state = state
      this.$message.success(res.data)
    },
    changeStatusDialog(row) {
      // 状态 0 禁用 1 启用
      let msg = ''
      if (row.state === '0') {
        msg = `是否启用${row.systemName}？`
      } else {
        msg = `是否停用${row.systemName}？`
      }
      this.$confirm(`${msg}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.changeStatus(row)
      })
    },
    searchList() {
      this.queryParams.pageNum = 1
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.queryParams.pageSize = pageSize
      this.queryParams.pageNum = 1
      this.getList()
    },
    handleCurrentChange(pageNum) {
      this.queryParams.pageNum = pageNum
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.box-card {
  min-height: calc(100% - 10px);
}
.mt30 {
  margin-top: 30px;
}
.fs20 {
  font-size: 14px;
}
.red {
  color: rgb(255, 90, 89);
}
.search-table-box {
  .down-export {
    float: right;
    margin-bottom: 20px;
    display: flex;
    button {
      margin-left: 10px;
    }
    button:first-child {
      margin: 0 10px;
    }
  }
}
/deep/ .upload-demo {
  text-align: center;
}
</style>
